<template>
    <div>
        <div class="right-menu shipping-right">

            <subHeader pageName="BLOGS"/>

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <moduleSearch :moduleName="'blog'" :disable="tourIsActive" ref="module_search"/>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="blogsTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == blogCategoriesList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != ''"
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Image">Image</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Title">Title</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Category">Category</p>
                                            </div>
                                        </th>   
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Author">Author</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Published At">Published At</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Active/Disable">Active/Disable</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Live Preview">Live Preview</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Views">Views</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="blogsLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="blogsList && blogsList.length == 0">
                                        <td colspan="9" style="text-align:center"><p class="mb-0">No Blogs Available</p></td>
                                    </tr>
                                    <tr v-for="(listedBlog, index) in blogsList" :key="index">
                                        
                                        <!-- checkbox & image block starts here -->
                                        <td @click="previewBlog(listedBlog,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label 
                                                    class="ctmTableCheckContainer" 
                                                    data-open="false" 
                                                    @click.prevent=" listedBlog.id != -1 ? checkedItemHandle(listedBlog.id) : '' "
                                                >
                                                    <input 
                                                        type="checkbox" 
                                                        v-model="checkedItems" 
                                                        :value="listedBlog.id" 
                                                        :disabled="tourIsActive || listedBlog.id == -1" 
                                                        data-open="false"
                                                    >
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                                <!-- for saved category -->
                                                <div class="ctmDataTableImgBox" data-open="true" v-if="listedBlog.id != -1">
                                                    <img 
                                                        :src="listedBlog.image ? listedBlog.image_src : '/images/no_image.png'" 
                                                        alt=""
                                                        v-if="listedBlog.id == -1"
                                                        data-open="true"
                                                        @error="handleImageError"
                                                    >
                                                    <img
                                                        :src="generateUrl(listedBlog) ? generateUrl(listedBlog) : '/images/no_image.png'"
                                                        alt=""
                                                        data-open="true"
                                                        @error="handleImageError"
                                                        v-else
                                                    >
                                                </div>
                                                <!-- for unsaved category -->
                                                <div class="ctmDataTableImgBox" data-open="true" v-else>
                                                    <img 
                                                        :src="listedBlog.addImage ? listedBlog.addImage_src : '/images/no_image.png'" 
                                                        alt=""
                                                        v-if="listedBlog.id == -1"
                                                        data-open="true"
                                                        @error="handleImageError"
                                                    >
                                                    <img
                                                        :src="generateUrl(listedBlog) ? generateUrl(listedBlog) : '/images/no_image.png'"
                                                        alt=""
                                                        data-open="true"
                                                        @error="handleImageError"
                                                        v-else
                                                    >
                                                </div>
                                            </div>
                                        </td>
                                        <!-- checkbox & image block starts here -->

                                        <!-- blog title block starts here -->
                                        <td class="mainTableTitle" @click="previewBlog(listedBlog,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="blog-title-field-3">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a blog title" 
                                                    v-model="listedBlog.title" 
                                                    @focus="blogSelection(listedBlog)" 
                                                    @blur="handleOTGUpdate(listedBlog,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- blog title block ends here -->

                                        <!-- blog category block starts here -->
                                        <td @click.prevent="previewBlog(listedBlog,index,'solid',$event)">
                                            <div class="selectCategoryBoxTable" data-open="true">
                                                <p data-open="true">
                                                    {{ listedBlog.category ? listedBlog.category.name.length > 15 ? listedBlog.category.name.substr(0,15) + '...' : listedBlog.category.name : '--' }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- blog category block ends here -->

                                        <!-- blog author block starts here -->
                                        <td @click.prevent="previewBlog(listedBlog,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ listedBlog.author ? (listedBlog.author.first_name + " " + listedBlog.author.last_name).length > 15 ? (listedBlog.author.first_name + " " + listedBlog.author.last_name).substr(0,15) + '...' : listedBlog.author.first_name + " " + listedBlog.author.last_name : "--" }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- blog author block ends here -->

                                        <!-- blog published at block starts here -->
                                        <td @click.prevent="previewBlog(listedBlog,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ listedBlog.created_at ? new Date(listedBlog.created_at).toLocaleDateString("en-US",{year:"numeric",month:"long",day:"numeric"}) : '--' }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- blog published at block ends here -->

                                        <!-- active/disabled block starts here -->
                                        <td @click="previewBlog(listedBlog,index,'input',$event)">
                                            <div class="actionSwitch" data-open="true">
                                                <label class="switch" data-open="false" v-if="listedBlog.id != -1">
                                                    <input
                                                        type="checkbox"
                                                        :id="`customSwitch-p-${listedBlog.id}`"
                                                        :value="listedBlog.isActive"
                                                        v-model="listedBlog.isActive"
                                                        :disabled="tourIsActive"
                                                        data-open="false"
                                                    >
                                                    <span class="slider" data-open="false" @click.stop="updateBlogStatusHandle(listedBlog,index)"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- active/disabled block ends here -->

                                        <!-- live preview block starts here -->
                                        <td @click.prevent="previewBlog(listedBlog,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" data-open="true">
                                                <button 
                                                    class="previewFunction" 
                                                    @click.prevent="livePreview(listedBlog)" 
                                                    :disabled="tourIsActive && listedBlog.id == -1" 
                                                    data-open="false"
                                                >
                                                    <i class="fa fa-external-link-square" aria-hidden="true" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- live preview block ends here -->

                                        <!-- views block starts here -->
                                        <td @click.prevent="previewBlog(listedBlog,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ listedBlog.views ? listedBlog.views.length : "--" }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- views block ends here -->

                                        <!-- action block starts here -->
                                        <td @click.prevent="previewBlog(listedBlog,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(listedBlog.id) && deleteLoader" data-open="false">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button 
                                                    class="delFunction" 
                                                    @click.prevent="deleteBlogHandle(listedBlog,index)" 
                                                    :disabled="tourIsActive" 
                                                    data-open="false"
                                                >
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- load more block starts here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- add/update blog form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow' : '' " >
                            <div class="tableActionBtns" id="blog-details-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-8" id="blog-title-field-2">
                                            <div class="viewOrderTitle">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a blog title" 
                                                    v-model="previewForm.title" 
                                                    @input="slugGeneration('title')"
                                                    :disabled="tourIsActive"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive" id="expand-form">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a class="active" id="category-detail-navbtn" href="#blogDetails">Blog Info</a>
                                        </li>
                                        <li v-if="!tourIsActive">
                                            <a href="#seo" id="category-seo-navbtn">SEO Essentials</a>
                                        </li>
                                    </ul>
                                    <div class="updateBtnBox" v-if="blogIsSaving">
                                        <loaderBtn/>
                                    </div>
                                    <div class="updateBtnBox" id="save-blog-btn" v-else>
                                        <button 
                                            form="blog-information" 
                                            type="submit"
                                            :disabled="tourIsActive"
                                        >
                                            {{ previewForm.id == -1 ? 'Save' : 'Update' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ValidationObserver ref="blogFormObserver">
                                <div class="productTableInfoBlock" id="blogDetails">
                                    <div class="productTableInfoWhiteBox">
                                        <form id="blog-information" @submit.prevent="validate()">
                                            <div class="tableFromFieldBox">
                                                <label for="blog-title-field-1">Title<span>*</span></label>
                                                <ValidationProvider name="title" rules="required|max:250" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="blog-title-field-1" 
                                                            id="blog-title-field-1" 
                                                            placeholder="Title" 
                                                            v-model="previewForm.title" 
                                                            @input="slugGeneration('title')" 
                                                            :disabled="tourIsActive"
                                                        >
                                                        <p class="fieldSeoMessage" v-if="previewForm.title && previewForm.title.length <= 50">
                                                            <span style="display: inline-flex;margin-right: 5px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                                </svg> 
                                                            </span>
                                                            Do not exceed 50 characters when entering the blog title for SEO
                                                        </p>
                                                        <p class="fieldSeoMessage" v-else-if="previewForm.title && previewForm.title.length > 50"> 
                                                            <span style="display: inline-flex;margin-right: 5px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                                </svg> 
                                                            </span>
                                                            Not SEO friendly
                                                        </p>
                                                        <p class="remainingTxt" v-if="previewForm.title">
                                                            <span>
                                                                {{previewForm.title ? previewForm.title.length <= 50 ? 50 - previewForm.title.length : 0 : 50 }} 
                                                            </span>
                                                            Remaining
                                                        </p>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="blog-slug-field">Slug<span>*</span></label>
                                                <ValidationProvider name="slug" rules="required|max:250" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="blog-slug-field" 
                                                            id="blog-slug-field" 
                                                            placeholder="Slug" 
                                                            v-model="previewForm.slug" 
                                                            @blur="slugGeneration('slug')"
                                                            :disabled="tourIsActive"
                                                        >
                                                        <p class="fieldSeoMessage" v-if="previewForm.slug && previewForm.slug.length <= 60">
                                                            <span style="display: inline-flex;margin-right: 5px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z" />
                                                                </svg> 
                                                            </span>
                                                            Do not exceed 60 characters when entering the blog slug for SEO
                                                        </p>
                                                        <p class="fieldSeoMessage" v-else-if="previewForm.slug && previewForm.slug.length > 60"> 
                                                            <span style="display: inline-flex;margin-right: 5px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z" />
                                                                </svg> 
                                                            </span>
                                                            Not SEO friendly
                                                        </p>
                                                        <p class="remainingTxt" v-if="previewForm.slug">
                                                            <span>
                                                                {{previewForm.slug ? previewForm.slug.length <= 60 ? 60 - previewForm.slug.length : 0 : 60 }} 
                                                            </span>
                                                            Remaining
                                                        </p>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="blog-excerpt-field">Excerpt<span>*</span></label>
                                                <ValidationProvider name="excerpt" rules="required|max:250" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="blog-excerpt-field" 
                                                            id="blog-excerpt-field" 
                                                            placeholder="Excerpt" 
                                                            v-model="previewForm.excerpt"
                                                            :disabled="tourIsActive"
                                                        >
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox" id="blog-category">
                                                <label for="blog-category-selector">Category<span>*</span></label>
                                                <div class="vueSelectBlock">
                                                    <ValidationProvider name="category" rules="required" v-slot="{ errors }">
                                                        <Multiselect
                                                            ref="parentSelection"
                                                            id="blog-category-selector"
                                                            v-model="previewForm.category"
                                                            :options="blogCategoriesList"
                                                            placeholder="Search and select a category"
                                                            :custom-label="customBlogCategoryName"
                                                            label="name"
                                                            track-by="id"
                                                            :allow-empty="false"
                                                            deselect-label="Can't remove the selected item"
                                                            :searchable="true"
                                                            @search-change="loadBlogCategories"
                                                            :loading="blogCategoriesLoader"
                                                            :disabled="tourIsActive"
                                                        >
                                                            <span slot="noResult">
                                                                No category found matching the searched keyword.
                                                            </span>
                                                        </Multiselect>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="tableFromFieldBox" v-if="previewForm.id != ''">
                                                <label for="blog-content-field">Content<span>*</span></label>
                                                <ValidationProvider name="content" rules="required|max:5000" v-slot="{ errors }">
                                                    <div class="quillDetailTxtBox">
                                                        <vue-editor 
                                                            id="blog-content-field"
                                                            name="blog-content-field"
                                                            useCustomImageHandler
                                                            @image-added="contentImageHandler"
                                                            :editorOptions="editorOptions"
                                                            v-model="previewForm.description"
                                                            :disabled="tourIsActive"
                                                        >
                                                        </vue-editor>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox" v-if="previewForm.id == -1">
                                                <label>Featured Image<span>*</span></label>
                                                <div class="imgUploaderTxt">
                                                    <input 
                                                        type="file" 
                                                        ref="addPreviewFileInput" 
                                                        @change.prevent="changeImage" 
                                                        id="feature_img" 
                                                        accept="image/png,image/jpg,image/jpeg,image/webp" 
                                                        hidden 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <label for="feature_img">
                                                        <div class="category-tab-img" id="blog-featured-image">
                                                            <img 
                                                                :src="previewForm.addImage ? 
                                                                previewForm.addImage_src ? 
                                                                previewForm.addImage_src : base_image_url + settings.store_id + '/blog/' + previewForm.addImage : '/images/no_image.png' " 
                                                                alt=""
                                                                @error="handleImageError"
                                                            />
                                                        </div>
                                                    </label>
                                                </div>
                                                <span class="text-danger" v-if="imageValidations.imgIsRequired">Image field is required</span>
                                            </div>
                                            <div class="tableFromFieldBox" v-else>
                                                <label>Featured Image<span>*</span></label>
                                                <div class="imgUploaderTxt">
                                                    <input 
                                                        type="file" 
                                                        ref="previewFileInput" 
                                                        @change.prevent="changeImagePreviewForm" 
                                                        id="feature_img1" 
                                                        accept="image/png,image/jpg,image/jpeg,image/webp" 
                                                        hidden
                                                        :disabled="tourIsActive"
                                                    >
                                                    <label for="feature_img1">
                                                        <div class="category-tab-img" id="blog-featured-image-update">
                                                            <img 
                                                                :src="previewForm.image ? 
                                                                previewForm.image_src ? 
                                                                previewForm.image_src : base_image_url + settings.store_id + '/blog/' + previewForm.image : '/images/no_image.png' " 
                                                                alt=""
                                                                @error="handleImageError"
                                                            />
                                                        </div>
                                                    </label>
                                                </div>
                                                <span class="text-danger" v-if="imageValidations.imgIsRequired">Image field is required</span>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="productTableInfoWhiteBox" id="seo">
                                        <div class="productTableInfoWhiteBoxTitle">
                                            <h5>SEO</h5>
                                        </div>
                                        <form @submit.prevent="validate()">
                                            <div class="tableFromFieldBox">
                                                <ValidationProvider name="meta title" rules="max:250" v-slot="{ errors }">
                                                    <label for="blog-meta-title-field">Meta Title</label>
                                                    <div class="tableFromFieldItem">
                                                        <input
                                                            type="text" 
                                                            name="blog-meta-title-field" 
                                                            id="blog-meta-title-field" 
                                                            placeholder="Meta title"
                                                            v-model="previewForm.seo_title"
                                                            :disabled="tourIsActive"
                                                        >
                                                        <p class="fieldSeoMessage" v-if="previewForm.seo_title && previewForm.seo_title.length <= 60">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                            </svg> 
                                                            Do not exceed 60 characters when entering the blog meta title for SEO
                                                        </p>
                                                        <p class="fieldSeoMessage" v-else-if="previewForm.seo_title && previewForm.seo_title.length > 60">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                            </svg> 
                                                            Not SEO friendly
                                                        </p>
                                                        <p class="remainingTxt" v-if="previewForm.seo_title">
                                                            <span>
                                                                {{previewForm.seo_title ? previewForm.seo_title.length <= 60 ? 60 - previewForm.seo_title.length : 0 : 60 }}
                                                            </span> Remaining
                                                        </p>
                                                    </div>
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <ValidationProvider name="meta description" rules="max:500" v-slot="{ errors }">
                                                    <label for="blog-meta-description-field">Meta Description</label>
                                                    <div class="tableFromFieldItem">
                                                        <textarea
                                                            name="blog-meta-description-field" 
                                                            id="blog-meta-description-field" 
                                                            placeholder="Meta description" 
                                                            v-model="previewForm.seo_description"
                                                            :disabled="tourIsActive"
                                                        >
                                                        </textarea>
                                                        <p class="fieldSeoMessage" v-if="previewForm.seo_description && previewForm.seo_description.length <= 300">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                            </svg> 
                                                            Do not exceed 300 characters when entering the blog meta desscription for SEO
                                                        </p>
                                                        <p class="fieldSeoMessage" v-else-if="previewForm.seo_description && previewForm.seo_description.length > 300">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                            </svg> 
                                                            Not SEO friendly
                                                        </p>
                                                        <p class="remainingTxt" v-if="previewForm.seo_description">
                                                            <span>{{previewForm.seo_description ? previewForm.seo_description.length <= 300 ? 300 - previewForm.seo_description.length : 0 : 300 }}</span> Remaining
                                                        </p>
                                                    </div>
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <div class="row align-items-center">
                                                    <div class="col-md-12">
                                                        <div class="ctmFeaturedCheckBox">
                                                            <label class="ctmFeaturedContainer" id="blog-featured-checkbox">Featured
                                                                <input 
                                                                    type="checkbox" 
                                                                    v-model="previewForm.isFeatured"
                                                                    :disabled="tourIsActive"
                                                                />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="ctmFlexRow">
                                            <div class="statusColumnBox">
                                                <div class="tableFromFieldBox" id="blog-active-switch">
                                                    <label>Status</label>
                                                    <div class="actionSwitch">
                                                        <label class="switch">
                                                            <input 
                                                                type="checkbox"
                                                                :value="previewForm.isActive"
                                                                v-model="previewForm.isActive"
                                                                :disabled="tourIsActive"
                                                            >
                                                            <span class="slider"></span>
                                                            <p class="ctmSwitchLabel">{{previewForm.isActive ? 'Active' : 'inActive'}}</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="statusColumnBox">
                                                <div class="tableFromFieldBox" id="blog-indexed-switch">
                                                    <label>Indexed</label>
                                                    <div class="actionSwitch">
                                                        <label class="switch">
                                                            <input
                                                                type="checkbox"
                                                                :value="previewForm.isIndexed"
                                                                v-model="previewForm.isIndexed"
                                                                :disabled="tourIsActive"
                                                            >
                                                            <span class="slider"></span>
                                                            <p class="ctmSwitchLabel">{{previewForm.isIndexed ? 'Yes' : 'No'}}</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <!-- add/update blog form ends here -->

                    </div>
                    <!-- data table starts here -->

                    <!-- bottom action bar starts here -->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != blogsList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-4" @click.prevent="blogsBulkActionHandle('active')">
                                    <p>ACTIVE</p>
                                </a>
                                <a href="" class="model-footer-1 mr-4" @click.prevent="blogsBulkActionHandle('disable')">
                                    <p>DISABLE</p>
                                </a>
                                <a href="#." class="model-footer-1 ml-4" @click="blogsBulkActionHandle('delete')">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- bottom action bar ends here -->

                </div>
            </div>

        </div>

        <!-- vue tour -->
        <v-tour name="blogsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import {MessageBox, Message, Loading } from 'element-ui'
import _ from 'lodash';
import subHeader from "@/components/subHeader.vue";
import { mapGetters } from 'vuex';
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";
import moduleStats from "@/components/moduleStats.vue";
import Multiselect from "vue-multiselect";

import { VueEditor } from "vue2-editor";
import moduleSearch from "@/components/ModuleSearch/index.vue";

export default {
    name: 'Blogs',
    metaInfo() {
        return {
            title: 'Content Management | Blogs | YeetCommerce',
        };
    },
    data() {
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,

            //blogs start
            blogsList: [],
            blogsLoading: false,
            blogSaved: false,
            blogCategoriesList: [],
            blogCategoriesLoader: false,
            blogCategoriesTimer: undefined,
            previewForm:{
                id: '',
                title: '',
                slug: '',
                excerpt: '',
                description: '',
                seo_title: '',
                seo_description: '',
                category_id: '',
                category: null,
                author: null,
                addImage: null,
                addImage_src: '',
                image: null,
                image_src: '',
                isActive: true,
                isIndexed: true,
                isFeatured: false,
                isTouched: false,
                created_by_user: 'admin',
            },
            defaultPreviewForm:{
                id: '',
                title: '',
                slug: '',
                excerpt: '',
                description: '',
                seo_title: '',
                seo_description: '',
                category_id: '',
                category: null,
                author: null,
                addImage: null,
                addImage_src: '',
                image: null,
                image_src: '',
                isActive: true,
                isIndexed: true,
                isFeatured: false,
                isTouched: false,
                created_by_user: 'admin',
            },
            tempBlog:{
                id: '',
                title: '',
                slug: '',
                excerpt: '',
                description: '',
                seo_title: '',
                seo_description: '',
                category_id: '',
                category: null,
                author: null,
                addImage: null,
                addImage_src: '',
                image: null,
                image_src: '',
                isActive: true,
                isIndexed: true,
                isFeatured: false,
                isTouched: false,
                created_by_user: 'admin',
            },
            tempPreviewBlog:{
                id: '',
                title: '',
                slug: '',
                excerpt: '',
                description: '',
                seo_title: '',
                seo_description: '',
                category_id: '',
                category: null,
                author: null,
                addImage: null,
                addImage_src: '',
                image: null,
                image_src: '',
                isActive: true,
                isIndexed: true,
                isFeatured: false,
                isTouched: false,
                created_by_user: 'admin',
            },
            blogIsSaving: false,
            //blogs end

            //image validations start
            imageValidations:{
                imgIsRequired: false,
                imgInvalidFormat: false,
                isNotImg: false,
            },
            //image validations end

            //checked items start
            checkedItems: [],
            deletingItems: [],
            deleteLoader: false,
            isMasterSearched: false,
            //checked items end

            editorOptions:{
                placeholder: 'Content',
                theme: 'snow',
                modules:{
                    toolbar:{
                        container:[ 
                            [{ "font": [] },],
                            [{'header': [1, 2, 3, 4, 5, 6, false]}],
                            ["blockquote", "code-block"],
                            ['bold', 'italic', 'underline', 'strike'],
                            ["clean"],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                            [{ 'script': 'sub'}, { 'script': 'super' }],
                            [{'color': []}, {'background': []}],
                            [{ 'direction': 'rtl' }, { 'align': [] }],
                            ['image']
                        ],
                    }
                }
            },

            //tour start
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //search bar step
                {
                    target: '#module-search',
                    content: `<strong>Search Blogs</strong><br>It enables you to search specific blogs.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                //blog categories steps
                {
                    target: '#preview-record',
                    content: `<strong>Blogs Table</strong><br>You can preview and edit the added blogs by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>Blog</strong><br>By clicking this button, you can add a new blog and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-details-preview',
                    content: `<strong>Blog Details Form</strong><br>This form can be used to add details about the blog and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-title-field-1',
                    content: `<strong>Blog Title Field</strong><br>You can add the title of blog using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-slug-field',
                    content: `<strong>Blog Slug Field</strong><br>You can add the slug of blog using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-excerpt-field',
                    content: `<strong>Blog Excerpt Field</strong><br>You can add the excerpt of blog using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-category',
                    content: `<strong>Blog Category Selection</strong><br>You can select the blog category using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-content-field',
                    content: `<strong>Blog Content Field</strong><br>You can add the content of blog using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-featured-image',
                    content: `<strong>Blog Featured Image</strong><br>It enables you to add featured image for the blog.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-meta-title-field',
                    content: `<strong>Blog Meta Title</strong><br>Meta title for the blog can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-meta-description-field',
                    content: `<strong>Blog Meta Description</strong><br>Meta description for the blog can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-featured-checkbox',
                    content: `<strong>Featured Blog</strong><br>Blog can be marked as featured using this checkbox.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#blog-active-switch',
                    content: `<strong>Blog Status</strong><br>Blog status can be changed between active and hide using this switch.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#blog-indexed-switch',
                    content: `<strong>Blog Indexed Status</strong><br>Blog index status can be changed between yes and no using this switch.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#blog-title-field-3',
                    content: `<strong>Blog Title Field</strong><br>You can also add the title of blog using this field too.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#blog-title-field-2',
                    content: `<strong>Blog Title Field</strong><br>You can also add the title of blog using this field too.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#save-blog-btn',
                    content: `<strong>Save Blog Details</strong><br>Added blog details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
            ],
            tourIsActive: false,
            //tour end
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn,
        VueEditor,
        Multiselect,
        moduleSearch
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            storeBlogs:'blogs_module/blogs',
            storeBlogsCurrentPage: 'blogs_module/currentPage',
            storeBlogsLastPage: 'blogs_module/lastPage',
            blogsSearchKeyword:'blogs_module/keyword',
            isPending:'blogs_module/isPending',
        }),
    },
    watch:{
        'storeBlogs':{
            handler:function(value){

                this.blogsLoading = false;

                if(this.previewForm.id == -1){
        
                    this.blogsList = _.cloneDeep(value);
                    
                    this.blogsList.unshift(this.previewForm);

                }else if(this.tempPreviewBlog.id != ''){

                    this.blogsList = _.cloneDeep(value);

                    this.blogsList[this.previewForm.index] = this.previewForm;

                }else{

                    this.blogsList = _.cloneDeep(value);

                }

                if(this.isMasterSearched){

                    this.previewSearchedBlog(this.blogsList[0],0);

                    this.isMasterSearched = false;

                }


            },deep:true
        },
        'previewForm':{
            handler:function(value){

                if((value.id == -1) && (value.title || value.slug || value.excerpt || value.description ||
                value.image || value.addImage || value.seo_title || value.seo_description )){

                    value.isTouched = true;

                }else{

                    value.isTouched = false;

                }

            },deep:true
        },
    },
    methods:{

        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.blogsTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.storeBlogsCurrentPage != this.storeBlogsLastPage){

                    this.$store.dispatch('blogCategories_module/fetchStoreBlogCategoriesRequest',{size: 15, page: this.storeBlogsCurrentPage + 1, loadData: 'more'});

                }

            }

        },
        //infinite scroll end

        //fields handling start
        slugGeneration(field){

            let slug = ''

            if(field == 'title'){

                slug = this.previewForm.title.toLowerCase().trim();
                slug = slug.replace(/[^a-zA-Z0-9 ]/g, '');
                slug = slug.replace(/\s/g, '-');
                slug = slug.replace(/(\W)\W*/g, '$1');
                slug = slug.replace(/-$/, ""); 

            }else if(field == 'slug'){

                slug = this.previewForm.slug.toLowerCase().trim();
                slug = slug.replace(/[^a-zA-Z0-9- ]/g, '');
                slug = slug.replace(/\s/g, '-');
                slug = slug.replace(/(\W)\W*/g, '$1');

            }

            this.previewForm.slug = slug;

        },
        //fields handling end

        //OTG update start
        blogSelection(listedBlog){

            this.tempBlog = _.cloneDeep(listedBlog);

        },
        async handleOTGUpdate(listedBlog,index){

            if(this.previewForm.id == ''){

                if(listedBlog.id != -1){

                    if(this.tempBlog.title != listedBlog.title){

                        if(listedBlog.title.length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The title field is required.",
                            })

                            this.blogsList[index].title = this.tempBlog.title;

                            return

                        }

                        if(listedBlog.title.length > 250){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The title field may not be greater than 250 characters.",
                            });

                            this.blogsList[index].title = this.tempBlog.title;

                            return

                        }

                        MessageBox.confirm(
                            'You have unsaved changes, save and proceed?',
                            'Confirm',
                            {
                                distinguishCancelAndClose: true,
                                confirmButtonText: 'Save',
                                cancelButtonText: 'Discard Changes',
                            }
                        ).then(async () => {

                            let formData = new FormData();

                            formData.append('field','title');
                            formData.append('blog_id',listedBlog.id);

                            formData.append('title',listedBlog.title);
                            
                            let loader = Loading.service({
                                text: "The blog is being updated. Please wait!",
                                fullscreen: true,
                            });

                            try{
                                let res = await this.$axios.post('/blog/selective/update',formData);
                                if(res.data.status_code == "1040"){

                                    this.$notify({
                                        type: 'success',
                                        title: 'Success',
                                        message: res.data.message,
                                    });

                                    this.$store.commit('blogs_module/update_blogs_list',res.data.blog);

                                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                                    this.$store.commit('gallery_module/CLEAR_MODULE');

                                }
                            }catch(error){

                                this.blogsList[index].title = this.tempBlog.title;
                                this.blogsList[index].slug = this.tempBlog.slug;

                                this.tempBlog = [];

                                if(error.response.data.error.id){

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.response.data.error.id[0],
                                    });

                                }else if(error.response.data.error.title){

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.response.data.error.title[0],
                                    });

                                }else if(error.response.data.error.slug){

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.response.data.error.slug[0],
                                    });

                                }else if(error.response.data.error.includes('storage limit has been reached')){

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.response.data.error,
                                    });

                                }else{

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.response.data.message,
                                    });

                                }

                            }finally{

                                loader.close();

                            }

                        }).catch((error) => {

                            this.blogsList[index].title = this.tempBlog.title;
                            this.blogsList[index].slug = this.tempBlog.slug;

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes discarded successfully.",
                            });

                            this.tempBlog = [];

                        })
                    
                    }

                }

            }else{

                if(listedBlog.id != -1){

                    if(listedBlog.id != this.previewForm.id){

                        if(this.tempBlog.title != listedBlog.title){

                            if(listedBlog.title.length == 0){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The title field is required.",
                                })

                                this.blogsList[index].title = this.tempBlog.title;

                                return

                            }

                            if(listedBlog.title.length > 250){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The title field may not be greater than 250 characters.",
                                });

                                this.blogsList[index].title = this.tempBlog.title;

                                return

                            }

                            MessageBox.confirm(
                                'You have unsaved changes, save and proceed?',
                                'Confirm',
                                {
                                    distinguishCancelAndClose: true,
                                    confirmButtonText: 'Save',
                                    cancelButtonText: 'Discard Changes',
                                }
                            ).then(async () => {

                                let formData = new FormData();

                                formData.append('field','title');
                                formData.append('blog_id',listedBlog.id);

                                formData.append('title',listedBlog.title);

                                let loader = Loading.service({
                                    text: "The blog is being updated. Please wait!",
                                    fullscreen: true,
                                });


                                try{
                                    let res = await this.$axios.post('/blog/selective/update',formData);
                                    if(res.data.status_code == "1040"){

                                        this.$notify({
                                            type: 'success',
                                            title: 'Success',
                                            message: res.data.message,
                                        });

                                        this.$store.commit('blogs_module/update_blogs_list',res.data.blog);

                                        this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                                        this.$store.commit('gallery_module/CLEAR_MODULE');

                                    }
                                }catch(error){

                                    this.blogsList[index].title = this.tempBlog.title;
                                    this.blogsList[index].slug = this.tempBlog.slug;

                                    this.tempBlog = [];

                                    if(error.response.data.error.id){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.id[0],
                                        });

                                    }else if(error.response.data.error.title){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.title[0],
                                        });

                                    }else if(error.response.data.error.slug){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.slug[0],
                                        });

                                    }else if(error.response.data.error.includes('storage limit has been reached')){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error,
                                        });

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.message,
                                        });

                                    }

                                }finally{

                                    loader.close();

                                }

                            }).catch((error) => {

                                this.blogsList[index].title = this.tempBlog.title;
                                this.blogsList[index].slug = this.tempBlog.slug;

                                this.$notify({
                                    type: "info",
                                    title: "Changes Discarded",
                                    message: "Changes discarded successfully.",
                                });

                                this.tempBlog = [];

                            })

                        }

                    }

                }

            }

        },
        //OTG update end

        //preview form open/close & reset start
        previewBlog(listedBlog,i,field,e){

            if(field == 'input'){

                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){
                
                    if(listedBlog.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    type: "warning",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                }
                            ).then(()=>{

                                let index = this.blogsList.findIndex((blog) => blog.id == -1);
                                
                                if(index != -1){
                    
                                    this.blogsList.splice(index, 1);
                    
                                }

                                if(!this.blogSaved){

                                    let tempIndex = this.blogsList.findIndex(x=>x.id == this.tempPreviewBlog.id);

                                    if(tempIndex != -1){

                                        this.blogsList[tempIndex] = this.tempPreviewBlog;

                                    }

                                }

                                let categoryIndex = this.blogCategoriesList.findIndex((category) => category.id == listedBlog.category.id)

                                if(categoryIndex == -1){

                                    this.loadBlogCategories(null);

                                }

                                this.resetImageValidations();

                                let blog = listedBlog;

                                this.previewForm = blog;
                                this.previewForm.index = i;
                                this.previewForm.image_src = null;
                                this.previewForm.image = blog.image;

                                this.checkedItems = [];

                                this.$refs.blogFormObserver.reset();

                                this.blogIsSaving = false;

                                this.tempPreviewBlog = _.cloneDeep(listedBlog);

                            }).catch(() => {})

                        }else{

                            let index = this.blogsList.findIndex((blog) => blog.id == -1);
                                
                            if(index != -1){
                
                                this.blogsList.splice(index, 1);
                
                            }
                
                            if(!this.blogSaved && (this.tempPreviewBlog.id != '' && this.tempPreviewBlog.id != listedBlog.id)){
                                
                                let tempIndex = this.blogsList.findIndex(x=>x.id == this.tempPreviewBlog.id);
                                
                                if(tempIndex != -1){

                                    this.blogsList[tempIndex] = this.tempPreviewBlog

                                }
                
                            }

                            if(this.tempPreviewBlog.id != '' && this.tempPreviewBlog.id == listedBlog.id){

                                let tempIndex = this.blogsList.findIndex(x=>x.id == this.tempPreviewBlog.id);

                                this.blogsList[tempIndex] = _.cloneDeep(this.storeBlogs[tempIndex]);

                                this.$refs.previewFileInput.value = ''

                                let categoryIndex = this.blogCategoriesList.findIndex((category) => category.id == listedBlog.category.id)

                                if(categoryIndex == -1){

                                    this.loadBlogCategories(null);

                                }

                                this.resetImageValidations();

                                let blog = _.cloneDeep(this.blogsList[tempIndex])

                                this.previewForm = this.blogsList[tempIndex]
                                this.previewForm.index = i
                                this.previewForm.image_src = null
                                this.previewForm.image = blog.image

                                this.checkedItems = []

                                this.$refs.blogFormObserver.reset();

                                this.blogIsSaving = false;

                                this.tempPreviewBlog = _.cloneDeep(this.blogsList[tempIndex])

                            }else{

                                this.$refs.previewFileInput.value = ''

                                let categoryIndex = this.blogCategoriesList.findIndex((category) => category.id == listedBlog.category.id)

                                if(categoryIndex == -1){

                                    this.loadBlogCategories(null);

                                }

                                this.resetImageValidations();

                                let blog = listedBlog;

                                this.previewForm = blog;
                                this.previewForm.index = i;
                                this.previewForm.image_src = null;
                                this.previewForm.image = blog.image;

                                this.checkedItems = [];

                                this.$refs.blogFormObserver.reset();

                                this.blogIsSaving = false;

                                this.tempPreviewBlog = _.cloneDeep(listedBlog);

                            }

                        }

                    }
                
                }

            }else{

                if(!this.tourIsActive){
                
                    if(listedBlog.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    type: "warning",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                }
                            ).then(()=>{

                                let index = this.blogsList.findIndex((blog) => blog.id == -1);
                                
                                if(index != -1){
                    
                                    this.blogsList.splice(index, 1);
                    
                                }

                                if(!this.blogSaved){

                                    let tempIndex = this.blogsList.findIndex(x=>x.id == this.tempPreviewBlog.id);

                                    if(tempIndex != -1){

                                        this.blogsList[tempIndex] = this.tempPreviewBlog

                                    }

                                }

                                let categoryIndex = this.blogCategoriesList.findIndex((category) => category.id == listedBlog.category.id)

                                if(categoryIndex == -1){

                                    this.loadBlogCategories(null);

                                }

                                this.resetImageValidations();

                                let blog = listedBlog;

                                this.previewForm = blog;
                                this.previewForm.index = i;
                                this.previewForm.image_src = null;
                                this.previewForm.image = blog.image;

                                this.checkedItems = [];

                                this.$refs.blogFormObserver.reset();

                                this.blogIsSaving = false;

                                this.tempPreviewBlog = _.cloneDeep(listedBlog);

                            }).catch(() => {})

                        }else{

                            let index = this.blogsList.findIndex((blog) => blog.id == -1);
                                
                            if(index != -1){
                
                                this.blogsList.splice(index, 1);
                
                            }
                
                            if(!this.blogSaved && (this.tempPreviewBlog.id != '' && this.tempPreviewBlog.id != listedBlog.id)){
                                
                                let tempIndex = this.blogsList.findIndex(x=>x.id == this.tempPreviewBlog.id);
                                
                                if(tempIndex != -1){

                                    this.blogsList[tempIndex] = this.tempPreviewBlog;

                                }
                
                            }

                            if(this.tempPreviewBlog.id != '' && this.tempPreviewBlog.id == listedBlog.id){

                                let tempIndex = this.blogsList.findIndex(x=>x.id == this.tempPreviewBlog.id);

                                this.blogsList[tempIndex] = _.cloneDeep(this.storeBlogs[tempIndex]);

                                this.$refs.previewFileInput.value = ''

                                let categoryIndex = this.blogCategoriesList.findIndex((category) => category.id == listedBlog.category.id)

                                if(categoryIndex == -1){

                                    this.loadBlogCategories(null);

                                }

                                this.resetImageValidations();

                                let blog = _.cloneDeep(this.blogsList[tempIndex]);

                                this.previewForm = this.blogsList[tempIndex];
                                this.previewForm.index = i;
                                this.previewForm.image_src = null;
                                this.previewForm.image = blog.image;

                                this.checkedItems = [];

                                this.$refs.blogFormObserver.reset();

                                this.blogIsSaving = false;

                                this.tempPreviewBlog = _.cloneDeep(this.blogsList[tempIndex]);

                            }else{

                                this.$refs.previewFileInput.value = '';

                                let categoryIndex = this.blogCategoriesList.findIndex((category) => category.id == listedBlog.category.id)

                                if(categoryIndex == -1){

                                    this.loadBlogCategories(null);

                                }

                                this.resetImageValidations();

                                let blog = listedBlog;

                                this.previewForm = blog;
                                this.previewForm.index = i;
                                this.previewForm.image_src = null;
                                this.previewForm.image = blog.image;

                                this.checkedItems = [];

                                this.$refs.blogFormObserver.reset();

                                this.blogIsSaving = false;

                                this.tempPreviewBlog = _.cloneDeep(listedBlog);

                            }

                        }

                    }
                
                }

            }

        },
        previewSearchedBlog(listedBlog,i){

            let index = this.blogsList.findIndex((blog) => blog.id == -1);
                                
            if(index != -1){

                this.blogsList.splice(index, 1);

            }

            if(!this.blogSaved){

                let tempIndex = this.blogsList.findIndex(x=>x.id == this.tempPreviewBlog.id);

                if(tempIndex != -1){

                    this.blogsList[tempIndex] = this.tempPreviewBlog;

                }

            }   

            let categoryIndex = this.blogCategoriesList.findIndex((category) => category.id == listedBlog.category.id)

            if(categoryIndex == -1){

                this.loadBlogCategories(null);

            }

            this.resetImageValidations();

            let blog = listedBlog;

            this.previewForm = blog;
            this.previewForm.index = i;
            this.previewForm.image_src = null;
            this.previewForm.image = blog.image;

            this.checkedItems = [];

            this.$refs.blogFormObserver.reset();

            this.blogIsSaving = false;

            this.tempPreviewBlog = _.cloneDeep(listedBlog);

        },
        closePreview(){

            if(this.previewForm.isTouched){

                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.", 
                    "Warning", 
                    {
                        type: "warning",
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                    }
                ).then(async () => {

                    let index = this.blogsList.findIndex((blog) => blog.id == -1);
                                
                    if(index != -1){
            
                        this.blogsList.splice(index, 1);
            
                    }

                    if(!this.blogSaved){
        
                        let tempIndex = this.blogsList.findIndex(x=>x.id == this.tempPreviewBlog.id);

                        if(tempIndex != -1){

                            this.blogsList[tempIndex] = this.tempPreviewBlog

                        }

                    }

                    if(this.previewForm.id != -1){

                        this.$refs.previewFileInput.value = ''

                    }else{

                        this.$refs.addPreviewFileInput.value = ''

                    }

                    this.previewForm.id = '';

                    // this.previewForm = _.cloneDeep(this.defaultPreviewForm)

                    this.blogSaved = false

                    this.tempBlog = _.cloneDeep(this.defaultPreviewForm)

                    this.tempPreviewBlog = _.cloneDeep(this.defaultPreviewForm)

                    this.resetImageValidations();

                }).catch(() => {})

            }else{

                let index = this.blogsList.findIndex((blog) => blog.id == -1);
                                
                if(index != -1){
        
                    this.blogsList.splice(index, 1);
        
                }

                if(!this.blogSaved){
        
                    let tempIndex = this.blogsList.findIndex(x=>x.id == this.tempPreviewBlog.id);

                    if(tempIndex != -1){

                        this.blogsList[tempIndex] = this.tempPreviewBlog

                    }

                }

                if(this.previewForm.id != -1){

                    this.$refs.previewFileInput.value = ''

                }else{

                    this.$refs.addPreviewFileInput.value = ''

                }

                this.previewForm.id = '';

                // this.previewForm = _.cloneDeep(this.defaultPreviewForm)
                    
                this.blogSaved = false
                
                this.tempBlog = _.cloneDeep(this.defaultPreviewForm)

                this.tempPreviewBlog = _.cloneDeep(this.defaultPreviewForm)

                this.resetImageValidations();

            }

        },
        resetPreview(){

            let index = this.blogsList.findIndex((blog) => blog.id == -1);
      
            if(index != -1){

                this.blogsList.splice(index, 1);

            }

            this.previewForm.id = '';

            // this.previewForm = _.cloneDeep(this.defaultPreviewForm);
                
            this.tempBlog = _.cloneDeep(this.defaultPreviewForm);

            this.tempPreviewBlog = _.cloneDeep(this.defaultPreviewForm);

            this.resetImageValidations();

        },
        createBlog(){

            if(this.blogsLoading){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "Blogs are being retrieved.",
                });

                return

            }

            if(this.blogIsSaving){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "A blog is currently being processed.",
                });

                return

            }

            let index = this.blogsList.findIndex(x=>x.id == -1);

            if(index != -1){

                // this.$notify({
                //   type: "error",
                //   title: "Can't Add New Blog",
                //   message: "you have an unsaved blog in the list",
                // });

                return
                
            }

            this.closePreview();

            this.previewForm = _.cloneDeep(this.defaultPreviewForm)

            this.previewForm.id = -1

            let tempBlog = this.previewForm

            this.blogsList.unshift(tempBlog)

            this.checkedItems = []

            this.loadBlogCategories(null);

            this.$refs.blogFormObserver.reset();

        },
        //preview form open/close & reset end

        //add/update & delete blog start
        validate(){

            this.$refs.blogFormObserver.validate().then(success => {

                if(!success){

                    const errors = Object.entries(this.$refs.blogFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                    this.$refs.blogFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });
                    
                    if(errors.length > 0){

                        if(this.previewForm.id == -1 && !this.previewForm.addImage){

                            this.imageValidations.imgIsRequired = true;

                        }else if(this.previewForm.id != -1 && !this.previewForm.image){

                            this.imageValidations.imgIsRequired = true;

                        }

                    }else{

                        if(this.previewForm.id == -1 && !this.previewForm.addImage){

                            this.imageValidations.imgIsRequired = true;

                            document.querySelector("#blog-featured-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }else if(this.previewForm.id != -1 && !this.previewForm.image){

                            this.imageValidations.imgIsRequired = true;

                            document.querySelector("#blog-featured-image-update").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }

                    }

                }else{

                    if(this.previewForm.id == -1 && !this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                        document.querySelector("#blog-featured-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                    }else if(this.previewForm.id != -1 && !this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                        document.querySelector("#blog-featured-image-update").scrollIntoView({ behavior: 'smooth',block: "center" });

                    }else if(this.previewForm.id == -1){

                        this.resetImageValidations();

                        this.addBlogHandle();

                    }else{

                        this.resetImageValidations();

                        this.updateBlogHandle();

                    }

                }

            });

        },
        async addBlogHandle(){

            let formData = new FormData();

            formData.append('title',this.previewForm.title);
            formData.append('slug',this.previewForm.slug);
            formData.append('excerpt',this.previewForm.excerpt);
            formData.append('description',this.previewForm.description);
            formData.append('category_id',this.previewForm.category.id);

            formData.append('isActive',this.previewForm.isActive ? 1 : 0);
            formData.append('isIndexed',this.previewForm.isIndexed ? 1 : 0);
            formData.append('isFeatured',this.previewForm.isFeatured ? 1 : 0);

            if(this.previewForm.seo_title){

                formData.append('seo_title',this.previewForm.seo_title);

            }

            if(this.previewForm.seo_description){

                formData.append('seo_description',this.previewForm.seo_description);

            }

            if(this.previewForm.addImage){

                formData.append('image', this.previewForm.addImage);

            }

            this.blogIsSaving = true;
            try{
                let res = await this.$axios.post('/blog/add',formData);
                if(res.data.status_code == "1039"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.$store.commit('blogs_module/add_blogs_list',res.data.blog);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    this.resetPreview();

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.title){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.title[0],
                        });

                    }else if(error.response.data.error.slug){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.slug[0],
                        });

                    }else if(error.response.data.error.category_id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.category_id[0],
                        });

                    }else if(error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    }); 

                }

            }finally{

                this.blogIsSaving = false;

            }

        },
        async updateBlogHandle(){

            let formData = new FormData();
            
            formData.append('id',this.previewForm.id);
            formData.append('title',this.previewForm.title);
            formData.append('slug',this.previewForm.slug);
            formData.append('excerpt',this.previewForm.excerpt);
            formData.append('description',this.previewForm.description);
            formData.append('category_id',this.previewForm.category.id);

            formData.append('isActive',this.previewForm.isActive ? 1 : 0);
            formData.append('isIndexed',this.previewForm.isIndexed ? 1 : 0);
            formData.append('isFeatured',this.previewForm.isFeatured ? 1 : 0);

            if(this.previewForm.seo_title){

                formData.append('seo_title',this.previewForm.seo_title);

            }

            if(this.previewForm.seo_description){

                formData.append('seo_description',this.previewForm.seo_description);

            }

            if(this.previewForm.image_src){

                formData.append('image', this.previewForm.image);

            }

            this.blogIsSaving = true;
            try{
                let res = await this.$axios.post('blog/update',formData);
                if(res.data.status_code == "1040"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    let index = this.blogsList.findIndex((listedBlog) => listedBlog.id == res.data.blog.id);

                    this.blogsList[index] = res.data.blog;

                    this.$store.commit('blogs_module/update_blogs_list',res.data.blog);

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    if(this.previewForm.id == res.data.blog.id){

                        this.blogSaved = true;

                        this.closePreview();

                    }

                }
            }catch(error){

                if(error.response){
                    
                    if(error.response.data.error.id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.id[0],
                        });

                    }else if(error.response.data.error.title){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.title[0],
                        });

                    }else if(error.response.data.error.slug){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.slug[0],
                        });

                    }else if(error.response.data.error.category_id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.category_id[0],
                        });

                    }else if(error.response.data.error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.blogIsSaving = false;

            }

        },
        async deleteBlogHandle(listedBlog,index){

            if(!this.tourIsActive){

                MessageBox.confirm(
                    'Are you sure you want to delete the blog?',
                    'Warning',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel',
                        type: 'warning',
                    }
                ).then(async ()=>{
                    
                    this.deletingItems.push(listedBlog.id);
                    this.deleteLoader = true;

                    let loader = Loading.service({
                        text: "The blog is being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.delete(`/blog/remove/${listedBlog.id}`);
                        if(res.data.status_code == '1041'){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: res.data.message,
                            });

                            // this.$store.commit('blogs_module/delete_blogs_list',index);

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                            this.$store.commit('gallery_module/CLEAR_MODULE');

                            //removing deleted item from checked items list
                            this.checkedItems = this.checkedItems.filter((item) => {

                                if(item != listedBlog.id){
                                    
                                    return item;
                                    
                                }

                            });

                            this.blogsLoading = true;

                            this.$store.dispatch('blogs_module/fetchStoreBlogsRequest',{size: 10, page: 1,loadData: 'new'});

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.blog_id){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.error.blog_id[0],
                                });

                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.message,
                                });  

                            }

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                        this.deletingItems = [];
                        this.deleteLoader = false;

                    }

                }).catch(() => {})

            }

        },
        async updateBlogStatusHandle(listedBlog,index){

            if(!this.tourIsActive){
            
                let formData = new FormData();

                formData.append('blog_id', listedBlog.id);
                formData.append('isActive', listedBlog.isActive == 1 ? 0 : 1);

                let loader = Loading.service({
                    text: "The blog is undergoing status update. Please wait!",
                    fullscreen: true,
                });

                try{
                    let res = await this.$axios.post('/blog/status/toggle',formData);
                    if(res.data.status_code == "10401"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        let payload = {
                            index: index,
                            updatedBlog: res.data.blog,
                        }

                        this.$store.commit('blogs_module/update_blogs_status',payload);

                    }
                }catch(error){

                    this.blogsList[index].isActive = !this.blogsList[index].isActive;

                    if(error.response){

                        if(error.response.data.error.blog_id){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.blog_id[0],
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{
                        
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                }

            }

        },

        async contentImageHandler(file, Editor, cursorLocation, resetUploader){

            if(!this.tourIsActive){

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){

                    let loader = Loading.service({
                        text: "Image is being uploaded. Please wait!",
                        fullscreen: true,
                    });

                    let formData = new FormData();

                    formData.append("image", file);

                    try{
                        let res = await this.$axios.post('blog/content/image/upload',formData);
                        if(res.data.status_code == "1280"){

                            const url = this.base_image_url + this.settings.store_id + '/blog/' + res.data.image_name;

                            Editor.insertEmbed(cursorLocation, "image", url);

                        }
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{

                        loader.close();

                        resetUploader();

                    }

                }else if(file.type.includes('image/')){

                    resetUploader();

                    let uploadedFileType = file.type.slice(6)

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                }else{

                    resetUploader();

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                }

            }

        },
        //add/update & delete blog end

        //bulk action start
        async blogsBulkActionHandle(action){

            this.resetPreview();

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                if(action == 'active' || action == 'disable'){

                    let formData = new FormData();

                    formData.append('resource_type', 'blog');
                    formData.append("resource_ids", JSON.stringify(this.checkedItems));
                    formData.append("action", action);

                    let loader = Loading.service({
                        text: "The selected blogs are undergoing status update. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.post("/bulk-action", formData);
                        if(res.data.status_code == "2031"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: 'Blogs status has been successfully changed.',
                            });

                            let payload = {
                                action: 'statusChange',
                                status: action == "active" ? 1 : 0,
                                listIds: this.checkedItems
                            }

                            this.$store.commit('blogs_module/blogs_bulk_action',payload);

                            this.checkedItems = [];

                        }
                    }catch(error){

                        this.deleteLoader = false;

                        if(error.response.data.message){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: "One of the blogs may have been deleted or isn't available.",
                            });

                        }

                    }finally{

                        loader.close();

                    }

                }else if(action == 'delete'){

                    let formData = new FormData();

                    formData.append('resource_type', 'blog');
                    formData.append("resource_ids", JSON.stringify(this.checkedItems));
                    formData.append("action", action);

                    this.deletingItems = _.cloneDeep(this.checkedItems);
                    this.deleteLoader = true;

                    let loader = Loading.service({
                        text: "The selected blogs are being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.post("/bulk-action", formData);
                        if(res.data.status_code == "2031"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: 'Blogs have been successfully removed.',
                            });

                            // let payload = {
                            //     action: 'delete',
                            //     listIds: this.checkedItems
                            // }

                            // this.$store.commit('blogs_module/blogs_bulk_action',payload);

                            this.blogsLoading = true;

                            this.$store.dispatch('blogs_module/fetchStoreBlogsRequest',{size: 10, page: 1,loadData: 'new'});

                            this.checkedItems = [];
                            this.deletingItems = [];
                            this.deleteLoader = false;

                        }
                    }catch(error){

                        this.deletingItems = [];
                        this.deleteLoader = false;

                        if(error.response.data.message){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: "One of the blogs may have been deleted or isn't available.",
                            });

                        }

                    }finally{

                        loader.close();

                    }

                }

            }).catch(() => {})

        },
        //bulk action end

        //search blog start
        async searchBlog(keyword){

            this.$refs.module_search.searchedKeyword = keyword

            this.$refs.module_search.search();

        },
        //search blog start

        //other methods start
        async changeImage(e){
      
            this.imageValidates = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }
            
            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 

                    this.imageValidations.imgIsRequired = false

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                
                    this.previewForm.addImage = file;
                    this.previewForm.addImage_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6);
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    if(!this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                    }
                
                    // this.imageValidations.imgInvalidFormat = true

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    if(!this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                    }
                    // this.imageValidations.isNotImg = true

                }

            }

        },
        async changeImagePreviewForm(e){

            this.imageValidates = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 

                    this.imageValidations.imgIsRequired = false

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                
                    this.previewForm.image = file;
                    this.previewForm.image_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false;

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6);
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    if(!this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                    }
                    
                    // this.imageValidations.imgInvalidFormat = true

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    if(!this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                    }

                    // this.imageValidations.isNotImg = true

                }

            }

        },
        resetImageValidations(){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

        },
        livePreview(listedBlog){

            if(listedBlog.isActive && (listedBlog.category.isActive)){

                window.open('http://' + this.settings.domain + '/blog/' + listedBlog.category.slug + '/' + listedBlog.slug);

            }else{

                this.$notify({
                    type: 'info',
                    title: 'Preview Unavailable',
                    message: "To preview, ensure that blog and it's associated category is active.",
                });

            }

        },
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        checkedItemHandle(blogId){

            if(!this.tourIsActive && blogId != -1 && !this.previewForm.id){

                let index = this.checkedItems.findIndex(item => item == blogId);

                if(index != -1){

                    this.checkedItems.splice(index,1)

                }else{

                    this.checkedItems.push(blogId)

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive && !this.previewForm.id){

                if((this.checkedItems.length == this.blogsList.length) ||
                (this.checkedItems.length == this.blogsList.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.blogsList.length){

                    this.checkedItems = [];
                    
                    this.blogsList.forEach(blog => {

                        if(blog.id != -1){

                            this.checkedItems.push(blog.id);

                        }

                    });

                }

            }

        },
        clearSelection(){

            MessageBox.confirm(
                'Do you really want to uncheck the selected records?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async () => {

                this.checkedItems = [];

            }).catch(() => {})

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');
            
        },
        blogsKeyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }

            }

        },
        generateUrl(listedBlog){

            if(listedBlog.image){

                if(typeof(listedBlog.image) == "string"){

                    return this.base_image_url + this.settings.store_id + '/blog/' + listedBlog.image;

                }else{

                    return listedBlog.image_src;

                }

            }

        },
        //other methods end

        //load blog categories method starts here
        customBlogCategoryName({ name }){

            if(name){

                if(name.length > 30){

                    let shortenedName = name.substr(0, 30);

                        return `${shortenedName}...`;

                    }else{

                        return `${name}`;

                    }

            }else{

                return ``;

            }

        },
        async loadBlogCategories(query){
        
            if(query){

                clearTimeout(this.blogCategoriesTimer);

                this.blogCategoriesTimer = setTimeout(async () => {

                    this.blogCategoriesLoader = true;
                    try{
                        let res = await this.$axios.get("getAllBlogCategories",{
                            params:{
                                offset: 15,
                                search: query ? query : null
                            }
                        });
                        if(res.data.status_code == "1042"){

                            this.blogCategoriesList = res.data.categories.data;

                        }
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{
                    
                        this.blogCategoriesLoader = false;

                    }

                }, 500);

            }else{

                this.blogCategoriesLoader = true;
                try{
                    let res = await this.$axios.get("getAllBlogCategories",{
                        params:{
                            offset: 15,
                            search: query ? query : null
                        }
                    });
                    if(res.data.status_code == "1042"){

                        this.blogCategoriesList = res.data.categories.data;

                        if(this.previewForm.id != '' && this.previewForm.id != -1){

                            if(this.previewForm.category){

                                let categoryIndex = this.blogCategoriesList.findIndex((category) => category.id == this.previewForm.category.id)

                                if(categoryIndex == -1){

                                    this.blogCategoriesList.unshift(this.previewForm.category);

                                }

                            }

                        }

                    }
                }catch(error){

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message,
                    });

                }finally{
                  
                    this.blogCategoriesLoader = false;

                }

            }

        },
        //load blog categories method ends here

        //tour methods start
        startBlogsTour(){

            this.$tours.blogsTour.start();

            this.tourIsActive = true;

            if(this.previewForm.id != ''){

                this.resetPreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                this.createBlog();

                document.querySelector(".tableActionBtns").style.position = "static";

                document.querySelector(".productSlideBox").style.overflowY  = "hidden";

            }

            if(currentStep >= 19){

                document.querySelector(".tableActionBtns").style.position = "sticky";

            }

            if(currentStep + 1 == 22){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep + 1 == 23){

                this.expand();

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 7){

                this.resetPreview();

                document.querySelector(".tableActionBtns").style.position = "sticky";

                document.querySelector(".productSlideBox").style.overflowY  = "auto";

            }

            if(currentStep <= 20){

                document.querySelector(".tableActionBtns").style.position = "static";

            }

            if(currentStep == 22){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep == 23){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

        },
        handleTourStop(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourFinish(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.resetPreview();

            this.tourIsActive = false;

        },
        //tour methods end

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },

    },
    mounted(){

        this.$refs.blogsTable.addEventListener('scroll', this.handleScrollEvent)

        window.addEventListener('keydown',this.blogsKeyEvent);

        window.addEventListener('beforeunload', (event) => {

            let index = null;

            this.blogsList.forEach((blog) => {

                if(blog.id == -1){

                    index = this.blogsList.indexOf(blog);

                }

            });

            if(index != null){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        //add/update form top navigation scroll handle
        this.$refs.viewInputForm.addEventListener("scroll",(e)=>{

            let maxScrollTop = e.target.scrollHeight - e.target.clientHeight;

            if(e.target.scrollTop >= maxScrollTop){

                document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {

                    item.classList.remove("active");

                });

                if(!this.tourIsActive){

                    document.querySelector('#category-seo-navbtn').classList.add('active');

                }

            }else{

                document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {

                    item.classList.remove("active");

                });

                if(!this.tourIsActive){

                    document.querySelector('#category-detail-navbtn').classList.add('active');

                }

            }

        });

        //master search
        if(this.$route.query.search){
      
            this.$refs.module_search.searchedKeyword = this.$route.query.search

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true

            }

            this.$router.replace({'query': null});

            this.$refs.module_search.search();

        }

    },  
    async beforeMount(){

        this.blogsLoading = true;
        try{
            if((!this.storeBlogs || this.storeBlogs == 0) && this.blogsSearchKeyword.length == 0){

                this.$store.dispatch('blogs_module/fetchStoreBlogsRequest',{size: 10, page: 1,loadData: 'new'});

            }else{

                this.blogsList = _.cloneDeep(this.storeBlogs);
                this.blogsLoading = false;

            }

            this.loadBlogCategories(null);

        }catch(error){

            this.blogsLoading = true;

            this.$message({
                type: 'error',
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

        window.removeEventListener('keydown',this.blogsKeyEvent);

    },
    beforeRouteLeave(to, from, next){

        let index = null;

        this.blogsList.forEach((account) => {

            if(account.id == -1){

                index = this.blogsList.indexOf(account);

            }

        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{

                this.resetPreview();

                next();

            }).catch(()=>{})

        }else{

            next();

        }

    },
}
window.myFunction = function (event) {
    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {
        item.classList.remove("active");
    });
    // mark as active selected menu item
    event.target.classList.add("active");
};
</script>

<style>
    .category-tab-img {
        height: 400px;
        width: 400px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
    }
    .category-tab-img:hover {
        border-color: #409EFF;
    }
    .category-tab-img img{
        height: 100%;
        width: 100%;
    }
    .category-tab-img svg{
        position: absolute;
        width: 50px;
        top: 43%;
        left: 0;
        right: 0;
        margin: 0 auto;
        fill: #d9d9d9;
    }
    .category-tab-img:hover svg{
        fill: #409EFF;
    }
</style>